import { useState, useEffect, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { connect, ConnectedProps } from 'react-redux';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import GardeLogo from '../GardeLogo';

import { getCurrentSite, updateSite } from 'concepts/site';
import {
  getSubscription,
  getSubscriptionInfo,
  isActive,
  isExpired,
  isTrial,
  fetchSubscription,
} from 'concepts/subscription';
import { getStripeSubscription, fetchStripeSubscription } from 'concepts/stripe-subscription';
import {
  PlanSelectEditFormType,
  getPlanSelectEditFormValues,
  storePlanSelectFormValues,
} from 'concepts/subscription-create';
import { fetchPlans, getAvailablePlans, AvailablePlans, Plan } from 'concepts/plan';
import { fetchMediaTrackers, getEnabledMediaTrackers } from 'concepts/media-tracker';
import { getViewerCountry } from 'concepts/i18n';
import {
  pathToSettingsSubscriptionDetails,
  pathToSettingsSubscriptionDetailsWithBankTransfer,
  pathToSettingsSubscriptionManageFeeds,
  pathToSettingsSubscriptionOverview,
} from 'services/routes';
import { getBasePlanName } from 'services/plan';
import InvoiceList from '../InvoiceList';
import styles from './SubscriptionPlanSelect.module.scss';
import WebComponent from 'utils/web-component';
import HelpScoutLink from 'components/HelpScoutLink';
import Icon from 'components/Icon';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import waitToBeReady from 'components/WaitToBeReadyHOC';
import formatPrice from 'services/format-price';
import Highlighter from 'components/Highlighter';
import { ReactComponent as CreditCardIcon } from 'images/icons/icon-credit-card.svg';
import { ReactComponent as BankIcon } from 'images/icons/icon-bank.svg';
import { getSepaDebitFeatureFlag } from 'concepts/feature-flags';
import { supportedSepaCountries } from 'services/countries';

type BillingCycleOptions = 'monthly' | 'yearly';
type CurrencyOptions = '' | 'USD' | 'CHF';

type SubscriptionPlanSelectProps = ConnectedProps<typeof connector>;

interface PlanOption extends Plan {
  value: string;
}

const getPlanOptions = (availablePlans: AvailablePlans, billingCycle: BillingCycleOptions): PlanOption[] => {
  if (!availablePlans || !availablePlans[billingCycle]) {
    return [];
  }

  const sortedPlans = sortBy(availablePlans[billingCycle], ['price_per_month']);

  return sortedPlans.map((plan: Plan) => ({
    ...plan,
    value: plan.id.replace('_yearly', '').replace('_usd', '').replace('_chf', ''),
  }));
};

const SubscriptionPlanSelect = ({
  availablePlans,
  fetchPlans,
  fetchStripeSubscription,
  fetchSubscription,
  fetchMediaTrackers,
  enabledMediaTrackers,
  savedFormValues,
  site,
  storePlanSelectFormValues,
  stripeSubscription,
  subscription,
  subscriptionInfo,
  updateSite,
  viewerCountry,
  sepaDebitEnabled,
}: SubscriptionPlanSelectProps) => {
  const [isUpdatingCurrency, setUpdatingCurrencyState] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (site?.id) {
      fetchPlans(site.id);
      fetchStripeSubscription(site.id);
      fetchSubscription(site.id);
      fetchMediaTrackers();
    }
  }, [site.id, fetchPlans, fetchSubscription, fetchStripeSubscription, fetchMediaTrackers]);

  // Set currency to USD for visitors from US
  useEffect(() => {
    if (viewerCountry === 'US' && site?.currency !== 'USD') {
      formik.setFieldValue('currency', 'USD');
      updateCurrency('USD').catch(() => {
        formik.setFieldValue('currency', site.currency);
      });
    }
  }, []); // eslint-disable-line

  const initialValues = useMemo((): PlanSelectEditFormType => {
    if (savedFormValues) {
      return savedFormValues;
    }

    return {
      billingCycle: null,
      currency: (site.currency || '') as CurrencyOptions,
      selectedPlan: getBasePlanName(subscription.next_plan || subscription.plan) || '',
      billingType: 'card',
    };
  }, [savedFormValues, site.currency, subscription.next_plan, subscription.plan]);

  const formik = useFormik({
    enableReinitialize: false,
    initialValues,
    onSubmit: (values): void => {
      const planId = [
        values.selectedPlan,
        values.currency.toLowerCase(),
        values.billingCycle === 'monthly' ? '' : values.billingCycle,
      ]
        .filter((value) => !!value)
        .join('_');

      // Save values to redux-store to persist values when using back
      storePlanSelectFormValues(values);

      // Plan key for getting max_media_trackers
      const litePlanKey = ['lite', values.currency.toLowerCase()].filter((value) => !!value).join('_');
      const maxSourcesForLitePlan = availablePlans.plans.find((plan) => plan.id === litePlanKey)?.max_media_trackers;

      const exceedsMaxFeedsForLitePlan =
        values.selectedPlan === 'lite' &&
        maxSourcesForLitePlan &&
        enabledMediaTrackers?.length &&
        enabledMediaTrackers.length > maxSourcesForLitePlan;

      if (exceedsMaxFeedsForLitePlan) {
        const query = new URLSearchParams({ plan: planId, billingType: values.billingType });
        return history.push(`${pathToSettingsSubscriptionManageFeeds(site?.site_url)}?${query.toString()}`);
      }

      switch (values.billingType) {
        case 'bankTransfer':
          return history.push(pathToSettingsSubscriptionDetailsWithBankTransfer(site.site_url, planId));
        case 'sepaDebit':
          return history.push(pathToSettingsSubscriptionDetails(site.site_url, planId, 'sepa-debit'));
        default:
          return history.push(pathToSettingsSubscriptionDetails(site.site_url, planId, 'card'));
      }
    },
  });

  // Prevent navigation for active subscriptions
  if (isActive(subscription)) {
    history.replace(pathToSettingsSubscriptionOverview(site.site_url));
  }

  const isActiveTrial = isTrial(subscription) && !!subscriptionInfo.daysLeft && subscriptionInfo.daysLeft >= 0;
  const planOptions = getPlanOptions(availablePlans, formik.values.billingCycle || 'monthly');

  const updateCurrency = (currency: CurrencyOptions) => {
    setUpdatingCurrencyState(true);

    // 1) Update site currency
    // 2) get updated plans
    // 3) hide currency loading state
    return updateSite(site.id, { currency })
      .then(() => fetchPlans(site.id))
      .then(() => setUpdatingCurrencyState(false))
      .catch(() => {
        setUpdatingCurrencyState(false);
        return Promise.reject();
      });
  };

  const isFromBankTransferCountry = ['DE', 'AT', 'CH'].includes(viewerCountry || '');
  const bankTransferSupportedPlans = ['pro', 'premium'];

  const isSepaDebitOptionAvailable = sepaDebitEnabled && supportedSepaCountries.includes(viewerCountry || '');
  const isBankTransferOptionAvailable =
    !isSepaDebitOptionAvailable &&
    !stripeSubscription &&
    (bankTransferSupportedPlans.includes(formik.values.selectedPlan) || isFromBankTransferCountry);

  const availablePaymentMethods = useMemo(() => {
    const paymentMethods = [
      {
        value: 'creditCard',
        title: 'Credit card',
        description: 'Hassle-free. Automatically charged until cancelled.',
        icon: CreditCardIcon,
      },
    ];

    if (isSepaDebitOptionAvailable) {
      paymentMethods.push({
        value: 'sepaDebit',
        title: 'SEPA Direct Debit',
        description: 'Automatically billed from your account until cancelled.',
        icon: BankIcon,
      });
    }

    if (isBankTransferOptionAvailable) {
      paymentMethods.push({
        value: 'bankTransfer',
        title: 'Bank transfer',
        description: 'A pdf invoice sent to my invocing address.',
        icon: BankIcon,
      });
    }

    return paymentMethods;
  }, [isSepaDebitOptionAvailable, isBankTransferOptionAvailable]);

  return (
    <>
      <Helmet>
        <title>Flockler {'\u203A'} Choose a plan</title>
      </Helmet>
      <form onSubmit={formik.handleSubmit} className={styles.SubscriptionPlanSelect}>
        <div className={styles.SubscriptionPlanSelectContent}>
          <div className={styles.section}>
            <div className={styles.subTitle}>How often would you like&nbsp;to&nbsp;get&nbsp;billed?</div>

            <div className={styles.SubscriptionOptions}>
              {[
                {
                  value: 'monthly',
                  title: 'Monthly billing',
                  description: 'Pay once a month, cancel anytime',
                },
                {
                  value: 'yearly',
                  title: 'Yearly billing',
                  description: 'Save by paying for a year upfront',
                  savePercentage: 15,
                },
              ].map((billingCycle: { value: string; title: string; description: string; savePercentage?: number }) => (
                <label
                  className={classnames(styles.SubscriptionOption, {
                    [styles.selected]: formik.values.billingCycle === billingCycle.value,
                  })}
                  key={billingCycle.value}
                >
                  <input
                    type="radio"
                    value={billingCycle.value}
                    checked={formik.values.billingCycle === billingCycle.value}
                    onChange={() => formik.setFieldValue('billingCycle', billingCycle.value)}
                  />
                  <span className={styles.radioReplacement} />
                  <div className={styles.content}>
                    <div className={styles.info}>
                      <span className={styles.name}>{billingCycle.title}</span>
                      <span className={styles.description}>{billingCycle.description}</span>
                    </div>

                    {!!billingCycle.savePercentage && (
                      <div className={styles.saveTag}>-{billingCycle.savePercentage}%</div>
                    )}
                  </div>
                </label>
              ))}
            </div>
          </div>

          <div className={classnames(styles.section, { [styles.sectionDisabled]: !formik.values.billingCycle })}>
            <div className={styles.subTitle}>Choose a plan that suits&nbsp;your&nbsp;needs</div>
            {subscription.percent_off && (
              <div className={styles.discountBlock}>
                Lucky you, we’ve added{' '}
                <span className="inline-block">
                  <Highlighter>
                    <strong>a {subscription.percent_off}% discount</strong>{' '}
                  </Highlighter>{' '}
                  to your account 🎉
                </span>
              </div>
            )}
            <div className={styles.SubscriptionOptions}>
              {(planOptions || []).map((plan) => (
                <label
                  key={plan.id}
                  className={classnames(styles.SubscriptionOption, {
                    [styles.selected]: formik.values.selectedPlan === plan.value,
                  })}
                >
                  <input
                    type="radio"
                    value={formik.values.selectedPlan}
                    checked={formik.values.selectedPlan === plan.value}
                    onChange={() => {
                      if (!formik.values.billingCycle) {
                        return;
                      }

                      // Change billing type back to creditCard if it's not availble anymore
                      if (!isFromBankTransferCountry && !bankTransferSupportedPlans.includes(plan.value)) {
                        formik.setFieldValue('billingType', 'creditCard');
                      }

                      formik.setFieldValue('selectedPlan', plan.value);
                    }}
                  ></input>
                  <span className={styles.radioReplacement} />
                  <div className={styles.content}>
                    <div className={styles.info}>
                      <span className={styles.name}>
                        <span className={styles.nameText}>
                          {plan.name}
                          Plan
                        </span>
                        {['business', 'pro', 'premium'].includes(plan.id) && <GardeLogo />}
                      </span>

                     <span className={styles.description}>
                        {plan.max_media_trackers ? `Store posts with ${plan.max_media_trackers} sources` : ' '}
                      </span>
                    </div>

                    <div className={styles.price}>
                      <span className={styles.monthlyPrice}>
                        {isUpdatingCurrency ? (
                          <LoadingIndicator className={styles.priceLoader} />
                        ) : (
                          formatPrice(plan.price_per_month, formik.values.currency)
                        )}
                      </span>
                      <span className={styles.priceDescription}>/ mo</span>
                    </div>
                  </div>
                </label>
              ))}

              <label className={classnames(styles.SubscriptionOption, styles.SubscriptionOptionPlaceholder)}>
                <div className={styles.content}>
                  <span className={styles.currencyOptions}>
                    <span className={styles.currencyOptionsTitle}>I would like to pay in</span>
                    <button
                      type="button"
                      className={classnames(styles.currencyButton, {
                        [styles.selected]: formik.values.currency === '',
                      })}
                      onClick={() => {
                        formik.setFieldValue('currency', '');
                        updateCurrency('').catch(() => {
                          formik.setFieldValue('currency', site.currency);
                        });
                      }}
                    >
                      <span className={styles.radio} /> EUR
                    </button>
                    <button
                      type="button"
                      className={classnames(styles.currencyButton, {
                        [styles.selected]: formik.values.currency === 'USD',
                      })}
                      onClick={() => {
                        formik.setFieldValue('currency', 'USD');
                        updateCurrency('USD').catch(() => {
                          formik.setFieldValue('currency', site.currency);
                        });
                      }}
                    >
                      <span className={styles.radio} /> USD
                    </button>
                  </span>
                </div>
              </label>
            </div>
          </div>

          {availablePaymentMethods.length > 1 && (
            <div className={classnames(styles.section, { [styles.sectionDisabled]: !formik.values.billingCycle })}>
              <div className={styles.subTitle}>How would you like to&nbsp;pay</div>

              <div className={styles.SubscriptionOptions}>
                {availablePaymentMethods.map((paymentMethod) => (
                  <label
                    className={classnames(styles.SubscriptionOption, styles.billingTypeOption, {
                      [styles.selected]: formik.values.billingType === paymentMethod.value,
                    })}
                    key={paymentMethod.value}
                  >
                    <input
                      type="radio"
                      value={formik.values.billingType}
                      checked={formik.values.billingType === paymentMethod.value}
                      onChange={() => formik.setFieldValue('billingType', paymentMethod.value)}
                    />
                    <span className={styles.radioReplacement} />
                    {paymentMethod.icon && <paymentMethod.icon className={styles.optionIcon} />}

                    <div className={styles.content}>
                      <div className={styles.info}>
                        <span className={styles.name}>{paymentMethod.title}</span>
                        <span className={styles.description}>{paymentMethod.description}</span>
                      </div>
                    </div>
                  </label>
                ))}
              </div>
            </div>
          )}

          {isActiveTrial && !!subscription.valid_until && (
            <p className={styles.SubscriptionIntro}>
              There’s no risk — If you change your mind, you can cancel before{' '}
              <strong>{format(new Date(subscription.valid_until), 'MMMM d')}</strong>{' '}
              <span className="inline-block">and you won’t be charged.</span>
            </p>
          )}
          <div className={styles.SubscriptionActions}>
            <WebComponent tag="fl-button"
              variant="success"
              size="large"
              type="submit"
              class="nowrap"
              disabled={!formik.values.selectedPlan || isUpdatingCurrency}
            >
              {formik.values.selectedPlan ? (
                <span className='whitespace-nowrap'>
                  Choose{' '}
                  <span key={formik.values.selectedPlan}>
                    {formik.values.selectedPlan}
                  </span>{' '}
                  with {formik.values.billingCycle} billing
                </span>
              ) : (
                'Choose plan first'
              )}
            </WebComponent>

            <p className="mt-3 text-sm text-slate-500">Coupons can be redeemed in the next step</p>
          </div>
        </div>

        <div className={styles.SubscriptionFooter}>
          <div className={styles.SubscriptionFeaturesLink}>
            <a href="https://flockler.com/features" target="_blank" rel="noopener noreferrer">
              See features included in all plans
            </a>
            <Icon type="external-link" className={styles.SubscriptionFeaturesLinkIcon} />
          </div>

          <div className={styles.SubscriptionContactMessage}>
            <div className={styles.SubscriptionContactMessageQuestion}>
              <Icon type="help-circle" className={styles.SubscriptionContactMessageIcon} />
              Got a question about payment methods?{' '}
            </div>
            <HelpScoutLink
              messageText="I have a question about payment methods: "
              className={styles.SubscriptionContactHelpscoutLink}
            >
              Chat with us
            </HelpScoutLink>
          </div>
        </div>
      </form>

      {isExpired(subscription) && (
        <section className="mt-8">
          <InvoiceList />
        </section>
      )}
    </>
  );
};

// Wrap with HOC to show spinner until props are loaded
const SubscriptionPlanSelectReady = waitToBeReady(SubscriptionPlanSelect, ['site', 'subscription', 'availablePlans']);

const mapStateToProps = (state: RootState) => ({
  availablePlans: getAvailablePlans(state),
  enabledMediaTrackers: getEnabledMediaTrackers(state),
  savedFormValues: getPlanSelectEditFormValues(state),
  site: getCurrentSite(state),
  subscription: getSubscription(state),
  subscriptionInfo: getSubscriptionInfo(state),
  stripeSubscription: getStripeSubscription(state),
  viewerCountry: getViewerCountry(state),
  sepaDebitEnabled: getSepaDebitFeatureFlag(state),
});

const mapDispatchToProps = {
  fetchPlans,
  fetchStripeSubscription,
  fetchSubscription,
  fetchMediaTrackers,
  updateSite,
  storePlanSelectFormValues,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SubscriptionPlanSelectReady);
